import { render, staticRenderFns } from "./InvestInProjectForced.vue?vue&type=template&id=4c122b35&"
import script from "./InvestInProjectForced.vue?vue&type=script&lang=js&"
export * from "./InvestInProjectForced.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports